const FULLY_MASKED_VALUE = '***************';

export interface AsyncInitiateValidationMaskedDataServerResponse {
  id: string;
  payee: AsyncInitiateValidationMaskedPayeeServerResponse;
  contactInfo: AsyncInitiateValidationMaskedContactInfoServerResponse;
  account: AsyncInitiateValidationMaskedAccountServerResponse;
}

interface AsyncInitiateValidationMaskedPayeeServerResponse {
  name: string;
  additionalCompanyName: string | undefined | null;
  isIndividual: boolean | undefined | null;
  countryCode: string;
  address: string | undefined | null;
  hasWebsite: boolean;
  legalIdentifier: AsyncInitiateValidationMaskedLegalIdentifierServerResponse | undefined | null;
}

export interface AsyncInitiateValidationMaskedLegalIdentifierServerResponse {
  type: string;
  id: string;
}

interface AsyncInitiateValidationMaskedContactInfoServerResponse {
  fullName: string | undefined | null;
  email: string;
  phoneNumber: string | undefined | null;
}

interface AsyncInitiateValidationMaskedAccountServerResponse {
  countryCode: string;
  bankCode: string | undefined | null;
  branchCode: string | undefined | null;
  accountNumber: string | undefined | null;
  iban: string | undefined | null;
  swiftCode: string | undefined | null;
  currency: string | undefined | null;
}

export interface AsyncInitiateValidationMaskedData {
  id: string;
  payee: AsyncInitiateValidationMaskedPayee;
  contactInfo: AsyncInitiateValidationMaskedContactInfo;
  account: AsyncInitiateValidationMaskedAccount;
}

interface AsyncInitiateValidationMaskedPayee {
  name: string;
  additionalCompanyName: string | null;
  isIndividual: boolean | null;
  countryCode: string;
  address: string | null;
  website: string | null;
  legalIdentifier: AsyncInitiateValidationMaskedLegalIdentifier | null;
}

export interface AsyncInitiateValidationMaskedLegalIdentifier {
  type: string;
  id: string;
}

interface AsyncInitiateValidationMaskedContactInfo {
  fullName: string | null;
  email: string;
  phoneNumber: string | null;
}

export interface AsyncInitiateValidationMaskedAccount {
  countryCode: string;
  bankCode: string | null;
  branchCode: string | null;
  accountNumber: string | null;
  iban: string | null;
  swiftCode: string | null;
  currency: string | null;
}

export function toAsyncInitiateValidationMaskedData(
  serverResponse: AsyncInitiateValidationMaskedDataServerResponse,
): AsyncInitiateValidationMaskedData {
  return {
    ...serverResponse,
    payee: toAsyncInitiateValidationMaskedPayee(serverResponse.payee),
    contactInfo: toAsyncInitiateValidationMaskedContactInfo(serverResponse.contactInfo),
    account: toAsyncInitiateValidationMaskedAccount(serverResponse.account),
  };
}

function toAsyncInitiateValidationMaskedPayee(
  serverResponse: AsyncInitiateValidationMaskedPayeeServerResponse,
): AsyncInitiateValidationMaskedPayee {
  return {
    ...serverResponse,
    website: serverResponse.hasWebsite ? FULLY_MASKED_VALUE : null,
    isIndividual: serverResponse.isIndividual ?? null,
    address: serverResponse.address ?? null,
    legalIdentifier: serverResponse.legalIdentifier ?? null,
    additionalCompanyName: serverResponse.additionalCompanyName ?? null,
  };
}

function toAsyncInitiateValidationMaskedContactInfo(
  serverResponse: AsyncInitiateValidationMaskedContactInfoServerResponse,
): AsyncInitiateValidationMaskedContactInfo {
  return {
    ...serverResponse,
    fullName: serverResponse.fullName ?? null,
    phoneNumber: serverResponse.phoneNumber ?? null,
  };
}

function toAsyncInitiateValidationMaskedAccount(
  serverResponse: AsyncInitiateValidationMaskedAccountServerResponse,
): AsyncInitiateValidationMaskedAccount {
  return {
    ...serverResponse,
    bankCode: serverResponse.bankCode ?? null,
    branchCode: serverResponse.branchCode ?? null,
    accountNumber: serverResponse.accountNumber ?? null,
    iban: serverResponse.iban ?? null,
    swiftCode: serverResponse.swiftCode ?? null,
    currency: serverResponse.currency ?? null,
  };
}

export enum AsyncDataInternalLoadErrors {
  errorLoadingRef = 'ERROR_LOADING_REF',
  errorLoadingAsyncMaskedData = 'ERROR_LOADING_ASYNC_MASKED_DATA',
  asyncMaskedDataInvalidQueryParamValue = 'ASYNC_MASKED_DATA_INVALID_UUID',
}

export interface AsyncInitiateValidationMaskedIbanAndAccountNumberFormFields {
  iban: string | null;
  showIban: boolean;
  accountNumber: string | null;
  showAccountNumber: boolean;
  isAccountOrIban: boolean;
}

export function calculateAsyncInitiateValidationMaskedIbanAndAccountNumber(
  asyncInitiateValidationMaskedAccount: AsyncInitiateValidationMaskedAccount | undefined,
): AsyncInitiateValidationMaskedIbanAndAccountNumberFormFields {
  if (!asyncInitiateValidationMaskedAccount) {
    return {
      iban: null,
      showIban: false,
      accountNumber: null,
      showAccountNumber: true,
      isAccountOrIban: true,
    };
  }

  if (!asyncInitiateValidationMaskedAccount.iban && !asyncInitiateValidationMaskedAccount.accountNumber) {
    return {
      iban: null,
      showIban: false,
      accountNumber: null,
      showAccountNumber: true,
      isAccountOrIban: true,
    };
  }

  return {
    iban: asyncInitiateValidationMaskedAccount.iban,
    showIban: !!asyncInitiateValidationMaskedAccount.iban,
    accountNumber: asyncInitiateValidationMaskedAccount.accountNumber,
    showAccountNumber: !!asyncInitiateValidationMaskedAccount.accountNumber,
    isAccountOrIban: false,
  };
}

export type BankBranchCodeFieldState = 'hidden' | 'disabled' | 'enabled';

export interface AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields {
  bankCode: string | null;
  bankCodeState: BankBranchCodeFieldState;
  branchCode: string | null;
  branchCodeState: BankBranchCodeFieldState;
  isBankCodeMayContainBranchCode: boolean;
}

export function calculateAsyncInitiateValidationMaskedBankCodeAndBranchCode(
  countryHasBankCode: boolean,
  countryHasBranchCode: boolean,
  asyncInitiateValidationMaskedAccount: AsyncInitiateValidationMaskedAccount | undefined,
): AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields {
  // Async mode not enabled
  // Or that async data does not have bank code and does not have branch code
  if (
    !asyncInitiateValidationMaskedAccount ||
    (!asyncInitiateValidationMaskedAccount.bankCode && !asyncInitiateValidationMaskedAccount.branchCode)
  ) {
    return {
      bankCode: null,
      bankCodeState: countryHasBankCode ? 'enabled' : 'hidden',
      branchCode: null,
      branchCodeState: countryHasBranchCode ? 'enabled' : 'hidden',
      isBankCodeMayContainBranchCode: countryHasBankCode && !countryHasBranchCode,
    };
  }

  if (countryHasBankCode && !countryHasBranchCode) {
    return calculateAsyncInitiateValidationMaskedBankCodeAndBranchCodeForCountriesWithBankCodeOnly(
      asyncInitiateValidationMaskedAccount,
    );
  }

  if (!countryHasBankCode && countryHasBranchCode) {
    return calculateAsyncInitiateValidationMaskedBankCodeAndBranchCodeForCountriesWithBranchCodeOnly(
      asyncInitiateValidationMaskedAccount,
    );
  }

  return {
    bankCode: asyncInitiateValidationMaskedAccount.bankCode,
    bankCodeState: asyncInitiateValidationMaskedAccount.bankCode ? 'disabled' : 'enabled',
    branchCode: asyncInitiateValidationMaskedAccount.branchCode,
    branchCodeState: asyncInitiateValidationMaskedAccount.branchCode ? 'disabled' : 'enabled',
    isBankCodeMayContainBranchCode: false,
  };
}

function calculateAsyncInitiateValidationMaskedBankCodeAndBranchCodeForCountriesWithBankCodeOnly(
  asyncInitiateValidationMaskedAccount: AsyncInitiateValidationMaskedAccount,
): AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields {
  // Bank and branch are empty
  if (!asyncInitiateValidationMaskedAccount.bankCode && !asyncInitiateValidationMaskedAccount.branchCode) {
    return {
      bankCode: null,
      bankCodeState: 'enabled',
      branchCode: null,
      branchCodeState: 'hidden',
      isBankCodeMayContainBranchCode: true,
    };
  }

  const branchCodeState = asyncInitiateValidationMaskedAccount.branchCode ? 'disabled' : 'hidden';
  return {
    bankCode: asyncInitiateValidationMaskedAccount.bankCode,
    bankCodeState: asyncInitiateValidationMaskedAccount.bankCode ? 'disabled' : 'hidden',
    branchCode: asyncInitiateValidationMaskedAccount.branchCode,
    branchCodeState: branchCodeState,
    isBankCodeMayContainBranchCode: branchCodeState === 'hidden',
  };
}

function calculateAsyncInitiateValidationMaskedBankCodeAndBranchCodeForCountriesWithBranchCodeOnly(
  asyncInitiateValidationMaskedAccount: AsyncInitiateValidationMaskedAccount,
): AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields {
  // Bank and branch are empty
  if (!asyncInitiateValidationMaskedAccount.bankCode && !asyncInitiateValidationMaskedAccount.branchCode) {
    return {
      bankCode: null,
      bankCodeState: 'hidden',
      branchCode: null,
      branchCodeState: 'enabled',
      isBankCodeMayContainBranchCode: false,
    };
  }

  const branchCodeState = asyncInitiateValidationMaskedAccount.branchCode ? 'disabled' : 'hidden';
  return {
    bankCode: asyncInitiateValidationMaskedAccount.bankCode,
    bankCodeState: asyncInitiateValidationMaskedAccount.bankCode ? 'disabled' : 'hidden',
    branchCode: asyncInitiateValidationMaskedAccount.branchCode,
    branchCodeState: branchCodeState,
    isBankCodeMayContainBranchCode: branchCodeState === 'hidden',
  };
}

interface SupplierRegistrationAsyncInitiateValidationProcessAccountDetails {
  bankCode: string | null;
  branchCode: string | null;
  accountNumber: string | null;
  iban: string | null;
  swiftCode: string | null;
}

interface SupplierRegistrationAsyncInitiateValidationProcessAddress {
  addressDetails: SupplierRegistrationAsyncInitiateValidationProcessAddressDetails | null;
}

interface SupplierRegistrationAsyncInitiateValidationProcessAddressDetails {
  countryCode: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
}

export interface SupplierRegistrationAsyncInitiateValidationProcessRequest {
  isIndividual: boolean | null;
  hasAgreed: boolean | null;
  fullName: string | null;
  email: string | null;
  phoneNumber: string | null;
  companyName: string | null;
  additionalCompanyName: string | null;
  address: SupplierRegistrationAsyncInitiateValidationProcessAddress | null;
  legalId: string | null;
  legalIdType: string | null;
  countryCode: string | null;
  companyWebsite: string | null;
  accountBankCountryCode: string | null;
  currency: string | null;
  accountDetails: SupplierRegistrationAsyncInitiateValidationProcessAccountDetails;
  abaRouting: string | null;
  furtherCredit: string | null;
  organizationReferenceId: string;
  canUseAch: boolean | null;
  canUseEft: boolean | null;
  dateOfBirth: number | null;
  registrationId: string | null;
  accountType: string | null;
}
